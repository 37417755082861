<template>
  <div id="infinite-list">
    <!-- <div class="page_container">
      <p
        style="
          border: 2px solid red;
          padding-left: 2rem;
          display: flex;
          gap: 2rem;
        "
      >
        <span>
          fixtureFilter = {{ fixtureFilter }} <br />
          sport_id ={{ sport_id }}
          <br />
          highlights = {{ highlights }} <br />
          boosted ={{ boosted }} <br />
          upcoming ={{ upcoming }} <br />
          all ={{ all }}
          <br />
        </span>
        <span>
          byTime = {{ market_filters.byTime }} <br />
          byLeague = {{ market_filters.byLeagues }} <br />
          byMarket = {{ market_filters.byMarket }}
        </span>
      </p>
    </div> -->
    <Competition
      v-show="!highlights && !upcoming && !boosted && !all"
      v-for="(l, index) in competitions"
      v-bind:key="getLeagueKey(l, index)"
      v-bind:competition="l"
      v-bind:is_visible="true"
      v-bind:upcoming="upcoming"
      v-bind:today="today"
      v-bind:tomorrow="tomorrow"
      v-bind:esport="esport"
      v-bind:highlights="highlights"
      v-bind:live="live"
      v-bind:date="date"
    >
    </Competition>

    <div v-if="highlights">
      <div>
        <Highlight
          v-show="highlights"
          v-for="(l, index) in highlightsData"
          v-bind:key="getLeagueKey(l, index)"
          v-bind:fixture="l"
          tab="highlights"
        >
        </Highlight>
      </div>

      <div class="load page_container" v-if="loadingGames">
        <FixturesSkeletonLoader :count="10" />
      </div>

      <div
        v-if="highlightsData.length < 1 && !loadingGames && !is_busy"
        class="page_container"
      >
        <div v-if="!is_busy && error" class="error">
          <p>Something Went wrong</p>
        </div>
        <div v-if="!is_busy && !error && highlightsData.length < 1">
          <NoData
            class="nodata"
            :title="$t('noGamesAvailable')"
            :description="$t('noGamesCategory')"
          />
        </div>
      </div>
    </div>
    <div v-if="boosted">
      <div>
        <Highlight
          v-show="boosted"
          v-for="(l, index) in boostedData"
          v-bind:key="getLeagueKey(l, index)"
          v-bind:fixture="l"
          tab="boosted"
          :boostedGame="true"
        >
        </Highlight>
      </div>
      <div class="load page_container" v-if="loadingGames">
        <FixturesSkeletonLoader :count="10" />
      </div>
      <div v-if="boostedData.length < 1 && !loadingGames && !is_busy">
        <div v-if="!is_busy && error" class="error">
          <p>Something Went wrong</p>
        </div>
        <NoData
          class="nodata"
          v-if="!is_busy && !error"
          :title="$t('noGamesAvailable')"
          :description="$t('noGamesCategory')"
        />
      </div>
    </div>
    <div v-if="upcoming">
      <div>
        <Highlight
          v-show="upcoming"
          v-for="(l, index) in upcomingData"
          v-bind:key="getLeagueKey(l, index)"
          v-bind:fixture="l"
          tab="upcoming"
        >
        </Highlight>
      </div>
      <div class="load page_container" v-if="loadingGames">
        <FixturesSkeletonLoader :count="10" />
      </div>
      <div v-if="upcomingData.length < 1 && !loadingGames && !is_busy">
        <div v-if="!is_busy && error" class="error">
          <p>Something Went wrong</p>
        </div>
        <NoData
          v-if="!is_busy && !error"
          class="nodata"
          :title="$t('noGamesAvailable')"
          :description="$t('noGamesCategory')"
        />
      </div>
    </div>
    <div v-if="all">
      <div class="load page_container" v-if="loadingGames">
        <FixturesSkeletonLoader :count="10" />
      </div>
      <div>
        <Highlight
          v-show="all"
          v-for="(l, index) in allData"
          v-bind:key="getLeagueKey(l, index)"
          v-bind:fixture="l"
          tab="countries"
        >
        </Highlight>
      </div>
      <div class="load page_container" v-if="loadingGames">
        <FixturesSkeletonLoader :count="10" />
      </div>
      <div v-if="allData.length < 1 && !loadingGames && !is_busy">
        <div v-if="!is_busy && error" class="error">
          <p>Something Went wrong</p>
        </div>
        <NoData
          v-if="!is_busy && !error"
          class="nodata"
          :title="$t('noGamesAvailable')"
          :description="$t('noGamesCategory')"
        />
      </div>
    </div>

    <div v-if="searchable">
      <div>
        <Highlight
          v-show="searchable"
          v-for="(l, index) in searchResults"
          v-bind:key="index"
          v-bind:fixture="l"
          tab="highlights"
        >
        </Highlight>
      </div>
      <div class="load page_container" v-if="loadingGames">
        <FixturesSkeletonLoader :count="10" />
      </div>
      <div
        v-if="searchResults.length < 1 && !loadingGames && !is_busy"
        class="page_container"
      >
        <div v-if="!is_busy && error" class="error">
          <p>Something Went wrong</p>
        </div>
        <!-- <div v-if="!is_busy && !error && searchResults.length < 1">
          <NoData
            class="nodata"
            title="No games available"
            description="We currently dont have any games for this Category"
          />
        </div> -->
      </div>
    </div>
    <div v-if="showInfiniteScroll">
      <scroll-loader
        v-show="showInfiniteScroll"
        :loader-method="loadOnScroll"
        :loader-disable="is_busy || page >= last_page"
      >
      </scroll-loader>
    </div>
  </div>
</template>

<script>
import mqtt from "mqtt";

const Competition = () => import("./Competition.vue");
const Highlight = () => import("./Highlight.vue");
// import { nextTick } from "vue";
import Vue from "vue";
import ScrollLoader from "vue-scroll-loader";
Vue.use(ScrollLoader);

import fixtures from "@/services/fixtures";
// import mqtt from "mqtt";
import FixturesSkeletonLoader from "@/components/ui/FixturesSkeletonLoader.vue";
import NoData from "@/components/ui/NoData.vue";

export default {
  name: "Games",
  data: function () {
    return {
      leagues: [],
      top_leagues: [],
      competitions: [],
      highlightsRawData: [],
      highlightsRawSearchData: [],
      loadingGames: false,
      firstTimeLoading: true,
      visible: [],
      busy: [],
      is_busy: false,
      visible_leagues: [],
      mqttClient: false,
      market_outcome_headers: [],
      per_page: 20,
      page: 1,
      last_page: 0,
      remaining_records: 0,
      error: false,
      fixtures: [],
      highlightsData: [],
      allData: [],
      boostedData: [],
      upcomingData: [],
      searchResults: [],
    };
  },
  components: {
    Competition,
    Highlight,
    FixturesSkeletonLoader,
    NoData,
  },
  props: {
    date: {
      required: false,
      default: "",
    },
    searchable: {
      required: false,
      default: false,
      type: Boolean,
    },
    search: {
      required: false,
      default: "",
      type: String,
    },

    upcoming: {
      required: false,
      default: false,
    },
    highlights: {
      required: false,
      default: false,
    },
    boosted: {
      required: false,
      default: false,
    },
    all: {
      required: false,
      default: false,
    },
    live: {
      required: false,
      default: false,
    },
    today: {
      required: false,
      default: false,
    },
    tomorrow: {
      required: false,
      default: false,
    },
    leo: {
      required: false,
      default: false,
    },
    sport: {
      required: false,
      default: 1,
    },
    esport: {
      required: false,
      default: false,
    },
  },
  mounted: function () {
    var vm = this;
    this.resetFilters();
    vm.market_outcome_headers = this.$store.state.market_outcome;
    this.is_busy = false;
    vm.visible_leagues = [];
    this.EventBus.$on("event:leagues:show", function (id) {
      var exists = false;
      vm.jQuery.each(vm.visible_leagues, function (k, v) {
        if (parseInt(v) === parseInt(id)) {
          exists = true;
        }
      });

      if (!exists) {
        vm.visible_leagues.push(parseInt(id));
      }
    });

    // this.$nextTick(function () {
    //   vm.autoRefreshUI(this.$vnode.tag);
    // });

    // nextTick(() => {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // });
    // this.initiateCall();
    this.initMqtt();
  },
  methods: {
    initMqtt: function () {
      let marketID = this.market_id; // allowed values "1", "186", "10", "29", "11", "26", "18"
      let specifier = "";
      let gameStatus = "prematch"; // either prematch or live should be updated based on the page the use is in
      if (this.live) {
        gameStatus = "live";
      }

      // on the home page where we load all the games from highlights endpoint, we want to get updates for all the games
      // but for only the selected market ID
      // in this scenario we subscribe to wilcard topic at the match level represented by +
      // further reference came be made at https://www.emqx.com/en/blog/advanced-features-of-mqtt-topics
      let matchID = "+"; // use + to subscribe to all matches, otherwise use a particular matchID

      let topics = [
        `feeds/${gameStatus}/market_status/${matchID}/${marketID}/${specifier}`, // subscribe to all ${gameStatus} games for marketID ${marketID} , this is only applicable to markets configured on highlights i.e "1", "186", "10", "29", "11", "26", "18"
        "feeds/producer_status", // subscribe to all producer status updates
      ];

      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;
      var vm = this;
      if (this.mqttClient !== false) {
        this.mqttClient.end();
      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      };

      var client = mqtt.connect(endpoint, options);

      client.on("connect", function () {
        // subscribe to home page topics to update matches
        vm.jQuery.each(topics, function (k, v) {
          client.subscribe(v, function (err) {
            if (!err) {
              console.log("subscribed to | " + v);
            }
          });
        });
      });

      client.on("message", function (topic, msg) {
        var payload = JSON.parse(msg.toString()); // converts binary message to text then parses it as JSON
        vm.uxUpdate(payload);
      });

      this.mqttClient = client;

      this.$store.dispatch("setWebSocketState", client);
    },

    showInfiniteScroll: function () {
      if (this.highlightsData.length > 1) {
        return true;
      }
      if (this.allData.length > 1) {
        return true;
      }
      if (this.boostedData.length > 1) {
        return true;
      }
      if (this.upcomingData.length > 1) {
        return true;
      }
      return false;
    },

    resetFilters: function () {
      this.$store.dispatch("setMarketFilters", {
        ...this.$store.state.market_filters,
        byTime: 0,
        byMarket: 0,
      });
    },
    initiateCall() {
      const vm = this;
      if (vm.searchable) {
        vm.search.length > 0 ? vm.getSearch() : (vm.is_busy = false);
      } else if (vm.live) {
        vm.getLive();
      } else if (vm.esport) {
        vm.getEsport();
      } else {
        const mappings = {
          highlights: "getHighlight",
          boosted: "getBoosted",
          all: "getAll",
          upcoming: "getUpcoming",
        };
        const key = Object.keys(mappings).find((k) => vm[k]);
        if (key) {
          vm[mappings[key]]();
        } else {
          vm.getCompetitions();
        }
      }
    },
    loadOnScroll() {
      // this.is_busy = true;
      const vm = this;
      const scrollMappings = {
        highlights: "getHighlight",
        boosted: "getBoosted",
        upcoming: "getUpcoming",
        all: "getAll",
      };

      const key = Object.keys(scrollMappings).find((k) => vm[k]);
      if (key) {
        vm[scrollMappings[key]]();
      }
    },
    getKey: function (fixture, index) {
      if (Array.isArray(fixture)) {
        var currentFixture = fixture[0];
      } else {
        currentFixture = fixture;
      }

      var prefix =
        currentFixture === undefined || currentFixture.match_id == undefined
          ? index
          : currentFixture.match_id;
      return Math.random()
        .toString(10)
        .replace("0.", "fixture-id-" + prefix + "-");
    },
    getLeagueKey: function (league, index) {
      var prefix =
        league === undefined || league.competition_id == undefined
          ? index
          : league.competition_id;
      return Math.random()
        .toString(10)
        .replace("0.", "competition-id-" + prefix + "-");
    },
    getCompetitions: function () {
      var vm = this;
      var endpoint = process.env.VUE_APP_URL_TOP_LEAGUES;

      if (this.live) {
        endpoint = process.env.VUE_APP_URL_LIVE_TOP_LEAGUES;
      }

      if (this.esport) {
        endpoint = process.env.VUE_APP_URL_ESPORT_TOP_LEAGUES;
      }

      endpoint = "/tournaments/{sport_id}";

      var path = endpoint.replace(
        "{sport_id}",
        parseInt(vm.sport_id) > 0 ? vm.sport_id : 1
      );
      path = path.replace("{count}", 0);

      var games_filter = {};

      if (this.date.length > 0) {
        games_filter.date = this.date;
      }

      if (parseInt(this.upcoming) > 0) {
        games_filter.upcoming = this.upcoming;
        games_filter.highlights = "";
      }

      games_filter.hour = vm.hour;
      games_filter.hours = vm.hour;

      fixtures
        .get(path)
        .then((res) => {
          var leagues = res.data.data;
          if (vm.highlight || vm.highlight) {
            vm.jQuery.each(leagues, function (k, v) {
              v.competition_id = v.tournament_id;
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;

              var exist = false;

              // check if league exists in current leagues
              vm.jQuery.each(vm.leagues, function (kk, vv) {
                if (vv.competition_id === v.competition_id) {
                  exist = true;
                }
              });

              if (!exist) {
                vm.leagues.push(v);
              }
            });
          } else {
            vm.leagues = leagues;
            vm.jQuery.each(vm.leagues, function (k, v) {
              v.competition_id = v.tournament_id;
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });
          }

          vm.$store.dispatch("setLeague", vm.leagues);

          vm.getGames();
          vm.autoRefreshUI(vm.$vnode.tag);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFixture: function (competitions) {
      var vm = this;
      var path = process.env.VUE_APP_URL_FIXTURES_COMPETITIONS;

      var payload = {
        highlights: 1,
        upcoming: 0,
        hours: this.hour,
        country_code: "",
        date: vm.date,
        keyword: "",
        per_page: 100,
        competitions: competitions,
        //page: 1,
        highlight_market_id: vm.market_id,
        //match_live_status: 0,
        //tournament_id: vm.competition_id,
        //category_id: vm.category_id,
      };

      if (this.highlights) {
        path = process.env.VUE_APP_URL_HIGHLIGHTS;
      } else if (this.today) {
        path = process.env.VUE_APP_URL_TODAYS_COMPETITIONS;
      } else if (this.live) {
        path = process.env.VUE_APP_URL_LIVE_ODDS;
      }

      path = "/search/{sport_id}";

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{competition_id}", 0);
      path = path.replace("{market_id}", vm.market_id);
      path = path.replace("{page}", 1);

      if (this.date.length > 0) {
        payload.date = this.date;
      }

      if (parseInt(this.upcoming) > 0) {
        payload.upcoming = this.upcoming;
        payload.highlights = "";
      }

      fixtures
        .get(path, { params: payload })
        .then((res) => {
          vm.is_busy = false;
          vm.visible_leagues = [];
          var results = res.data.data;
          vm.jQuery.each(vm.leagues, function (k, v) {
            // get fixtures
            var fx = [];

            vm.jQuery.each(results, function (kk, vv) {
              vv.competition_id = vv.tournament_id;

              if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                fx.push(vv);
                vm.EventBus.$emit("event:leagues:show", v.competition_id);
              }
            });

            v.fixtures = fx;
            v.is_visible = true;
            vm.leagues[k] = v;
          });
          vm.competitions = vm.leagues;
          vm.autoRefreshUI(vm.$vnode.tag);
        })
        .catch((err) => {
          vm.busy = false;
          if (err.response) {
            vm.setError("Failed", err.response.data.message);
          } else if (err.request) {
            console.log(JSON.stringify(err.request));
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },
    getAllLive: function () {
      var vm = this;

      var path = process.env.VUE_APP_URL_LIVE_ALL;

      path = path.replace("{sport_id}", 0);

      fixtures
        .post(path, JSON.stringify({}))
        .then((res) => {
          var games = res.data.message;
          vm.leagues = games.competitions;

          vm.jQuery.each(vm.leagues, function (k, v) {
            v.fixtures = [];
            v.is_busy = false;
            v.is_visible = false;
            v.has_fixture = false;
            vm.leagues[k] = v;
          });

          vm.$store.dispatch("setLeague", vm.leagues);

          vm.is_busy = false;
          vm.visible_leagues = [];

          var results = games.data;
          vm.jQuery.each(vm.leagues, function (k, v) {
            // get fixtures
            var fx = [];

            vm.jQuery.each(results, function (kk, vv) {
              if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                fx.push(vv);
                vm.EventBus.$emit("event:leagues:show", v.competition_id);
              }
            });

            v.fixtures = fx;
            v.is_visible = true;
            vm.leagues[k] = v;
          });

          vm.competitions = vm.leagues;

          vm.getCompetitions();
        })
        .catch((err) => {
          vm.busy = false;
          if (err.response) {
            vm.setError("Failed", err.response.data.message);
            // console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            console.log(JSON.stringify(err.request));
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },
    getSearch: function () {
      var vm = this;
      console.log("fetching search...");
      const path =
        process.env.VUE_APP_BASE_FIXTURE_URL + `/search/${vm.sport_id || 1}`;
      const dat = {
        page: this.page,
        search: String(this.search),
        per_page: 20,
      };
      this.fetchData(path, dat, "searchResults");
    },
    getHighlight() {
      const path =
        this.$route.name === "search"
          ? `/search/${this.sport_id}`
          : `/highlights/${this.sport_id}`;
      const dat =
        this.$route.name === "search"
          ? {
              sport_id: parseInt(1),
              page: parseInt(this.page),
              per_page: parseInt(this.per_page),
              highlight_market_id: parseInt(this.market_id),
              search: String(this.search),
            }
          : {
              page: this.page,
              per_page: 20,
              highlight_market_id: this.market_filters.byMarket || 1,
            };
      this.fetchData(path, dat, "highlightsData");
    },
    getBoosted() {
      const path = `/highlights/${
        parseInt(this.sport_id) > 0 ? this.sport_id : 1
      }`;
      const dat = {
        page: this.page,
        per_page: 20,
        boosted: 1,
        highlight_market_id: this.market_filters.byMarket || 1,
      };

      this.fetchData(path, dat, "boostedData");
    },
    getUpcoming() {
      const path = `/highlights/${
        parseInt(this.sport_id) > 0 ? this.sport_id : 1
      }`;
      const dat = {
        page: this.page,
        per_page: 20,
        upcoming: 1,
        highlight_market_id: this.market_filters.byMarket || 1,
      };

      this.fetchData(path, dat, "upcomingData");
    },
    getAll() {
      const path = `/highlights/${
        parseInt(this.sport_id) > 0 ? this.sport_id : 1
      }`;
      const dat = {
        page: this.page,
        per_page: 20,
        boosted: 0,
        upcoming: 0,
        highlight_market_id: this.market_filters.byMarket || 1,
      };

      this.fetchData(path, dat, "allData");
    },
    fetchData(path, params, dataKey) {
      const vm = this;
      if (vm.is_busy || vm.loadingGames) {
        return;
      }

      if (
        !this.firstTimeLoading &&
        parseInt(vm.page) > parseInt(vm.last_page)
      ) {
        vm.is_busy = false;
        this.loadingGames = false;
        return;
      }
      if (this.market_filters.tournament_id) {
        params.tournament_id = this.market_filters.tournament_id;
      }

      if (this.market_filters.byTime) {
        params[this.market_filters.byTime] = 1;
      }
      if (this.market_filters.start) {
        params.start = this.market_filters.start;
      }
      if (this.market_filters.end) {
        params.end = this.market_filters.end;
      }

      vm.is_busy = true;
      this.loadingGames = true;
      fixtures
        .get(path, { params })
        .then((res) => {
          vm.is_busy = false;
          const data = res.data.data;
          if (res.data.data === null) {
            this.loadingGames = false;
            this.last_page = 0;
            this.remaining_records = 0;
            this[dataKey] = [];
            return;
          }
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;
          if (this.page === 1) {
            this[dataKey] = data;
          } else {
            const newData = [];
            vm.jQuery.each(data, function (k, v) {
              v.competition_id = v.tournament_id;
              newData.push(v);
            });
            vm[dataKey] = [...vm[dataKey], ...newData];
          }
          if (!this.firstTimeLoading) {
            this.page = parseInt(this.page) + 1;
          }
          this.loadingGames = false;
          this.firstTimeLoading = false;
        })
        .catch((err) => {
          vm.is_busy = false;
          this.loadingGames = false;
          this.firstTimeLoading = false;
          this.handleError(err);
        });
    },

    handleError(err) {
      this.error = true;
      if (err.response) {
        this.setError(err.response.data.message);
      } else if (err.request) {
        console.error(JSON.stringify(err.request));
      } else {
        console.error(JSON.stringify(err));
      }
    },

    getTodayFixtures: function () {
      var vm = this;
      var path = "/highlights/{sport_id}";
      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{market_id}", vm.market_id);
      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }
      if (vm.is_busy) {
        console.log("Current page " + vm.page + " but is busy ");
        return;
      }
      vm.page = parseInt(vm.page) + 1;
      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);
      var top_leagues = new Object();

      let payload = {
        page: 1, //vm.page,
        per_page: 20, //vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        //category_id: vm.category_id,
        upcoming: 0,
        boosted: 0,
        today: 1,
        //hours: vm.hour,
      };

      fixtures
        .get(path, { params: payload })
        .then((res) => {
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          var highlightsRawData = res.data.data;
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;

          if (parseInt(vm.page) === 1) {
            vm.highlightsRawData = [];
          }

          if (highlightsRawData.length === 0) {
            vm.last_page = vm.page - 1;
          }

          vm.jQuery.each(highlightsRawData, function (k, v) {
            v.competition_id = v.tournament_id;
            vm.highlightsRawData.push(v);
          });
          vm.filterHighlight();
          vm.jQuery.each(vm.highlightsRawData, function (k, v) {
            var tournament = v.tournament;
            var competition_id = v.tournament_id;
            var country = v.country;

            var ob = {
              country_name: country,
              competition_id: competition_id,
              competition: country + "/" + tournament,
            };

            top_leagues["c_" + competition_id] = ob;
          });
          var t_l = [];
          vm.jQuery.each(top_leagues, function (k, v) {
            t_l.push(v);
          });
          vm.top_leagues = t_l;
          vm.$store.dispatch("setLeague", vm.top_leagues);
        })
        .catch((err) => {
          vm.EventBus.$emit("event:busy", false);
          vm.busy = false;
          if (err.response) {
            vm.setError("Failed", err.response.data.message);
          } else if (err.request) {
            console.log(JSON.stringify(err.request));
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },

    clearData: function () {
      this.highlightsData = [];
      this.boostedData = [];
      this.upcomingData = [];
      this.allData = [];
    },

    filterHighlight: function () {
      var vm = this;

      vm.highlightsData = [];

      if (parseInt(vm.competition_id) < 1) {
        vm.highlightsData = vm.highlightsRawData;
        return;
      }

      vm.jQuery.each(vm.highlightsRawData, function (k, v) {
        if (parseInt(vm.competition_id) === parseInt(v.competition_id)) {
          vm.highlightsData.push(v);
        }
      });
    },

    filterSearchHighlight: function () {
      var vm = this;

      vm.highlightsData = [];

      if (parseInt(vm.competition_id) < 1) {
        vm.highlightsData = vm.highlightsRawSearchData;
        return;
      }

      vm.jQuery.each(vm.highlightsRawSearchData, function (k, v) {
        if (parseInt(vm.competition_id) === parseInt(v.competition_id)) {
          vm.highlightsData.push(v);
        }
      });
    },

    getLive: function () {
      var vm = this;

      var path = process.env.VUE_APP_URL_LIVE_SPORT;
      //var path = process.env.VUE_APP_URL_LIVE;

      path = path.replace("{sport_id}", 1);

      fixtures
        .post(path, JSON.stringify({}))
        .then((res) => {
          var games = res.data.message;
          vm.leagues = games.competitions;

          vm.jQuery.each(vm.leagues, function (k, v) {
            v.fixtures = [];
            v.is_busy = false;
            v.is_visible = false;
            v.has_fixture = false;
            vm.leagues[k] = v;
          });

          vm.$store.dispatch("setLeague", vm.leagues);

          vm.is_busy = false;
          vm.visible_leagues = [];

          var results = games.data;

          vm.jQuery.each(vm.leagues, function (k, v) {
            // get fixtures
            var fx = [];

            vm.jQuery.each(results, function (kk, vv) {
              if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                fx.push(vv);
                vm.EventBus.$emit("event:leagues:show", v.competition_id);
              }
            });

            v.fixtures = fx;
            v.is_visible = true;
            vm.leagues[k] = v;
          });

          vm.competitions = vm.leagues;

          vm.getCompetitions();
        })
        .catch((err) => {
          vm.busy = false;

          vm.loading = "";

          if (err.response) {
            vm.setError("Failed", err.response.data.message);
            console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            console.log(JSON.stringify(err.request));
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },

    getGames: function () {
      var vm = this;

      var c = 10;
      var b = 0;

      var competitions = [];

      this.jQuery.each(vm.leagues, function (k, v) {
        b++;
        var competition_id = v.competition_id;
        // get fixtures

        if (b < c && v.fixtures.length === 0) {
          competitions.push(competition_id);
        }
      });

      vm.getFixture(competitions.join(","));
    },

    reloadUI: function () {
      this.autoRefreshUI(this.$vnode.tag);
    },
  },
  computed: {
    fixtureFilter: function () {
      return this.$store.state.fixture_filter;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },

    market_filters: function () {
      return this.$store.state.market_filters;
    },

    loadingDistance: function () {
      return 50;
    },
    market_outcome: function () {
      return this.market_outcome_headers;
    },
    games: function () {
      return this.$store.state.games;
    },
    allLoaded: function () {
      return (
        this.$store.state.games.current_page > 0 &&
        this.$store.state.games.current_page ===
          this.$store.state.games.last_page
      );
    },
    busy1: function () {
      return this.$store.state.busy;
    },
    sport_name: function () {
      return this.$store.state.sport_name;
    },
    market_id: function () {
      return this.$store.state.market_filters.byMarket;
    },
    competition_id: function () {
      return this.$store.state.competition_id;
    },
    hour: function () {
      return this.$store.state.hour;
    },
  },
  watch: {
    fixtureFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.page = 1;
        this.firstTimeLoading = true;
        this.error = false;
        this.clearData();
        this.initMqtt();
        this.initiateCall();
      }
    },
    market_filters(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.page = 1;
        this.firstTimeLoading = true;
        this.error = false;
        this.clearData();
        this.initMqtt();
        this.initiateCall();
      }
    },
    sport_id: function (newValue, oldValue) {
      if (parseInt(newValue) !== parseInt(oldValue)) {
        this.page = 1;
        this.firstTimeLoading = true;
        this.error = false;
        this.resetFilters();
        this.clearData();
        this.initMqtt();
        if (this.highlights || this.boosted || this.all || this.upcoming) {
          this.initiateCall();
        } else {
          this.getCompetitions();
        }
      }
    },
    market_id: function (newValue, oldValue) {
      this.clearData();
      if (parseInt(newValue) === parseInt(oldValue)) {
        return;
      }
      if (this.highlights || this.boosted || this.all || this.upcoming) {
        this.per_page = 20;
        this.page = 0;
        this.last_page = 0;
        this.remaining_records = 0;
        this.highlightsRawData = [];
        this.initMqtt();
        this.initiateCall();
      } else {
        if (this.visible_leagues.length > 0) {
          this.competitions = [];
          this.initMqtt();
          this.getFixture(this.visible_leagues.join(","));
        }
      }
    },
    fetchSearchResults() {
      const searchResults = [];
      this.$emit("search-results", searchResults);
    },
    search: function (newValue, oldValue) {
      if (newValue !== oldValue && newValue.length > 0) {
        this.getSearch();
      }
    },
    date: function (newValue, oldValue) {
      console.log(
        "date newValue ==> " + newValue + " oldValue ==> " + oldValue
      );
      this.getCompetitions();
    },
    competition_id: function (newValue, oldValue) {
      if (parseInt(newValue) === parseInt(oldValue)) {
        return;
      }
      if (this.highlights) {
        this.filterHighlight();
      } else {
        if (this.visible_leagues.length > 0) {
          this.competitions = [];
          this.getFixture(this.visible_leagues.join(","));
        }
      }
    },
  },
  beforeDestroy: function () {
    console.log("beforeDestroy");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>

<style scoped>
.error {
  color: red;
  text-align: center;
  padding: 2rem;
  margin-bottom: 4rem;
}

#infinite-list {
  .loader {
    opacity: 0;
  }
}
.nodata {
  /* outline: 2px solid red; */
  margin-bottom: 4rem;
}
</style>
